#sub {
    margin-top: 10px;
    float: right;
}

#photo {
    width: 50px;
}

.page {
    position: absolute;
    right: 0;
    bottom: -39px;
}